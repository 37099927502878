export const COMMUNITY_FEATURE_FULL_FRAGMENT = `
  fragment communityFeatureFullFragment on CommunityFeature {
    uid
    comment
    enabled
    feature {
        uid
        product
        type
        name
        key
    }
  }
`;
